import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import RowWrapper from '../utils/rowWrapper';

import Footer from '../components/footer'
// import Contact from '../components/contact-button';


import Header from "../components/header-section";
import Walk from "../images/walk.jpg";
import Reach from "../images/reach.jpg";
import Library from "../images/library.jpg";
import Play from "../images/play.jpg"
import Garage from "../images/garage.jpg"
import Mud from "../images/mud.jpg"
import Shelf from "../images/shelves.jpg"
import Drawer from "../images/drawer.jpg"
import Hook from "../images/hook.jpg"
import Rack from "../images/rack.jpg"
import Basket from "../images/basket.jpg"
import Cabinet from "../images/cabinet.jpg"

import BracketRight from "../images/bracket-right.png";
import BracketLeft from "../images/bracket-left.png";

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const Main = styled.div`
  margin-top: 8rem;

h1 {
    font-size: 6rem;
    color: ${props => props.theme.colors.color};
    text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
  }

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 3rem;
      width: 70%;
      margin: 0 auto;
      line-height: 3.5rem;
    }
  }
`

const GridSection = styled.div`
  margin-top: 15rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 30rem);
  row-gap: 1.5rem;
  column-gap: 1.5rem;
  grid-template-areas: "walk walk reach library"
                      "play garage garage mud"
                      "every every shelf drawer"
                      "hook rack basket cabinet"
                      "bracket bracket bracket more"
  ;

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 20rem);
    grid-template-areas: "walk walk reach"
                      "play garage library"
                      "mud every every"
                      "rack shelf shelf"
                      "hook hook basket"
                      "drawer cabinet more"
                      "bracket bracket bracket"
  ;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    margin-top: 5rem;
    grid-template-rows: repeat(7, 10rem);
  }
`

const GridItemStyles = styled.div`
  /* height: 30rem; */
  grid-area: ${props => props.area};

  @media ${props => props.theme.breaks.down('sm')} {
    /* height: 10rem; */
  }
`

const Image = styled.div`
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url(${props => props.image});
  background-size: ${props => props.size ? props.size : "cover"};
  background-position: ${props => props.pos ? props.pos : "center"};
  width: 100%;
  height: 100%;

  span {
    position: absolute;
    font-family: "Museo Sans Rounded 700", sans-serif;
    font-size: 2.2rem;
    color: ${props => props.theme.colors.body};
    display: inline-block;
    text-align: center;
    top: 80%;
    width: 100%;

  }

  @media ${props => props.theme.breaks.down('sm')} {
    span {
      font-size: 1.2rem;
      top: auto;
      bottom: 10%;
    }
  }
`

const BlueBox = styled.div`
  background-color: ${props => props.theme.colors.color};
  /* width: max-content; */
  height: 100%;
  padding: 10rem 1rem;

  h4 {
    font-family: "Museo Sans Rounded 900", sans-serif;
    font-size: 4rem;
    text-transform: none;
    text-align: center;
    margin: 0;
    color: ${props => props.theme.colors.body};
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding: 5rem 1rem;

    h4 {
      font-size: 3rem;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    padding: 4rem 1rem;

    h4 {
      font-size: 1.5rem;
    }
  }
`

const Bracket = styled.div`
  position: relative;
  height: 100%;
  padding: 10rem 1rem;

  h4 {
    font-family: "Museo Sans Rounded 700", sans-serif;
    font-size: 4rem;
    text-transform: none;
    text-align: center;
    margin: 0;
  }

  img {
    height: 100%;
    opacity: .5;
  }

  .abs {
    position: absolute;
    top: 0;
  }

  .bracket-left-ab {
    left: 0;
  }

  .bracket-right-ab {
    right: 0;
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding: 5rem 1rem;
    h4 {
      font-family: "Museo Sans Rounded 500", sans-serif;
      font-size: 3rem;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    padding: 3rem 2rem;
    h4 {
      font-family: "Museo Sans Rounded 500", sans-serif;
      font-size: 1.5rem;
    }
  }
`



 
const Closet = ({ data }) => {

  const gi = useRef()

  useEffect(() => {
    const areaTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".grid-section",
        start: "top 60%",
        toggleActions: "play none none reverse"
      },
      onStart: () => {
        if (gi.current)
          gi.current.style.willChange = "transform"
      },
      onComplete: () => {
        if (gi.current)
          gi.current.style.willChange = ""
      }
    });

    areaTl.fromTo(".area-grid", {autoAlpha: 0, scale: .7}, {autoAlpha: 1, scale: 1, duration: 1, stagger: .3})
  }, [])

  const image = getImage(data.file)


  const GridItem = ({children, area}) => {
    return <GridItemStyles ref={gi} className="area-grid" area={area}>{children}</GridItemStyles>
  }

 
 return (
  <Layout pageTitle="Create A Space - Closets">
    <Header img={image} />

    <RowWrapper>
      <Main>
        <h1>WHAT CAN We DO?</h1>

        <GridSection className="grid-section">
          <GridItem area="walk"><Image image={Walk}><span>Walk-ins and</span></Image></GridItem>
          <GridItem area="reach"><Image image={Reach}><span>Reach-ins and</span></Image></GridItem>
          <GridItem area="library"><Image image={Library}><span>Libraries and</span></Image></GridItem>

          <GridItem area="play"><Image image={Play}><span>Playrooms and</span></Image></GridItem>
          <GridItem area="garage"><Image image={Garage}><span>Garages and</span></Image></GridItem>
          <GridItem area="mud"><Image pos="40% 80%" size="150%" image={Mud}><span>Mudrooms and</span></Image></GridItem>

          <GridItem area="every"><BlueBox><h4>Everywhere else we
            can organize you with</h4></BlueBox></GridItem>
          <GridItem area="shelf"><Image pos="80% 50%" size="170%" image={Shelf}><span>Shelves</span></Image></GridItem>
          <GridItem area="drawer"><Image pos="20% 80%" size="210%" image={Drawer}><span>Drawers</span></Image></GridItem>

          <GridItem area="hook"><Image pos="70% 10%" size="200%" image={Hook}><span>Hooks</span></Image></GridItem>
          <GridItem area="rack"><Image pos="80% 40%" size="190%" image={Rack}><span>Racks</span></Image></GridItem>
          <GridItem area="basket"><Image pos="90% 70%" size="350%" image={Basket}><span>Baskets</span></Image></GridItem>
          <GridItem area="cabinet"><Image pos="100% 90%" size="240%" image={Cabinet}><span>Cabinets</span></Image></GridItem>

          <GridItem area="bracket"><Bracket><img className="bracket-left-ab abs" src={BracketRight} alt="" /><h4>Officially, we’re custom closet <br /> designers and installers. There’s more.</h4>
          <img className="bracket-right-ab abs" src={BracketLeft} alt="" />
          </Bracket></GridItem>
          <GridItem area="more"><BlueBox><h4>And <br />more.</h4></BlueBox></GridItem>
        </GridSection>
      </Main>
    </RowWrapper>

    <Footer cta="Tell us what you need." subheader="Let’s see how we can help." />
  </Layout>
 )
}

export default Closet;


export const query = graphql`
query ClosetQuery {
  file(relativePath: { eq: "header-closets.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1800, height: 1000, quality: 100)
      }
    }
  }
`